import React from "react";
import ProductsVideoLayout from "../Layouts/ProductsSectionLayout";
import { ProductsSections } from "../../constants/imports/products";

export default function ExpenseTracking() {
  return (
    <section className="mt-24" id="expenses">
      <ProductsVideoLayout section={ProductsSections[1]} index={1} />
    </section>
  );
}
