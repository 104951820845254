import React from "react";
import { NavBar, Footer } from "./index"; // Import NavBar and Footer
import { Outlet, ScrollRestoration } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <div className="flex min-h-screen flex-col">
        <NavBar />
        <main className="mt-[var(--nav-height)]">
          <Outlet />
        </main>
        <Footer />
      </div>
      <ScrollRestoration />
    </>
  );
};

export default Layout;
