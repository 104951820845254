import { AnimatePresence, m } from "framer-motion";
import { SlArrowDown } from "react-icons/sl";

interface IAccordion {
  i: number;
  expanded: number | false;
  setExpanded: (arg: number | false) => void;
  children: React.ReactNode;
  title: string;
}

export default function Accordion({
  i,
  title,
  expanded,
  setExpanded,
  children,
}: IAccordion) {
  const isOpen = i === expanded;

  return (
    <>
      <m.div
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
        className="flex justify-between py-4 text-xl font-medium capitalize"
      >
        <span>{title}</span>
        <SlArrowDown
          className={`transition-transform ${isOpen ? "rotate-180" : ""}`}
          size={16}
        />
      </m.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <m.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            {children}
          </m.section>
        )}
      </AnimatePresence>
    </>
  );
}
