import React from "react";
import { m } from "framer-motion"; // Import motion from Framer Motion

import {
  FBgHeader,
  accounting,
  checklist,
  sale,
} from "../../constants/imports/features";
import { mobileHeroBg } from "../../constants/import";

const FeatureHeader = () => {
  const features = [
    {
      icon: checklist,
      title: "Inventory",
    },
    {
      icon: accounting,
      title: "Expenses",
    },
    {
      icon: sale,
      title: "Sales",
    },
  ];
  return (
    <m.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="relative mb-20 h-[80vh] px-6 pb-8 pt-14 lg:h-[800px] lg:px-20 lg:pb-20 lg:pt-32"
    >
      <img
        src={FBgHeader}
        alt="Hero Background "
        className="absolute left-0 top-0 -z-30 hidden h-full w-full object-fill lg:block"
      />
      <img
        src={mobileHeroBg}
        alt="Hero Background "
        className="absolute left-0 top-0 -z-30 block h-full w-full object-cover lg:hidden"
      />
      <div className="w-full lg:w-[80%]">
        <h1 className="px-2 text-center text-xl font-medium text-black lg:text-left lg:text-4xl lg:font-medium">
          Explore Our Core Features for Seamless Business Management{" "}
        </h1>
        <p className="mt-4 px-0 text-center text-sm font-medium text-spingoGray lg:px-3 lg:text-left">
          From Inventory to Sales, We&#39;ve Got it All.
        </p>
      </div>
      {/* List Items */}
      <m.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="my-16 flex justify-center space-x-8 lg:justify-start"
      >
        {features.map((feature) => (
          <m.div
            key={feature.title}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center"
          >
            <div className="rounded-lg border border-blue-400 bg-faint px-4 py-3">
              <img
                src={feature.icon}
                alt={feature.title}
                className="h-[50px] w-[60px] object-contain"
              />
            </div>
            <p className="mt-2 text-center text-spingoGray">{feature.title}</p>
          </m.div>
        ))}
      </m.div>
    </m.div>
  );
};

export default FeatureHeader;
