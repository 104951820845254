import {
  HeroBg,
  mobileHeroBg,
  googleStoreBlack,
  appStoreBlack,
} from "../../constants/import";
import { m } from "framer-motion";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="relative flex aspect-[0.52] h-auto items-start justify-center pb-8 pt-16 sm:pt-64 lg:aspect-auto lg:h-[calc(100vh-var(--nav-height))] lg:items-center lg:justify-start lg:pb-20 lg:pt-10">
      <div className="container">
        <img
          src={HeroBg}
          alt="Hero Background "
          className="absolute left-0 top-0 -z-30 hidden h-full w-full object-cover lg:block"
        />
        <img
          src={mobileHeroBg}
          alt="Hero Background "
          className="absolute left-0 top-0 -z-30 block h-full w-full object-cover lg:hidden"
        />
        <div>
          <m.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full px-0 sm:px-10 lg:w-1/2 lg:px-0"
          >
            <h1 className="text-center text-3xl font-medium leading-snug text-black sm:text-4xl lg:text-left lg:text-5xl xl:leading-snug">
              Elevate Your Business with SPINGO - Your Ultimate Operations Ally
            </h1>
            <div className="mt-4 text-center text-base font-medium leading-normal text-black sm:text-lg lg:text-left lg:text-base">
              <p className="hidden lg:block">
                Harness the power of SPINGO's all-encompassing platform that
                combines invoicing, inventory management, and accounting tools
                into one seamless experience. Our advanced AI-driven expiry
                tracking system elevates your operations, ensuring you're always
                a step ahead.
              </p>
              <p className="block lg:hidden">
                SPINGO's platform integrates invoicing, inventory, and
                accounting seamlessly with AI expiry tracking for effortless
                operation.
              </p>
            </div>
          </m.div>
          <m.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="mt-6 flex w-full flex-row items-center justify-center gap-2 lg:justify-start lg:gap-8"
          >
            <AppDownloadLinks />
          </m.div>
        </div>
      </div>
    </section>
  );
};

export function AppDownloadLinks() {
  return (
    <>
      <Link to="#google-store">
        <img
          src={googleStoreBlack}
          alt="Google PlayStore"
          className="w-[clamp(150px,27vw,200px)]"
        />
      </Link>
      <Link to="#app-store">
        <img
          src={appStoreBlack}
          alt="App Store"
          className="w-[clamp(150px,27vw,200px)]"
        />
      </Link>
    </>
  );
}
export default Hero;
