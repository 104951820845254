import React from "react";
import { Button } from "../Generic";
import { dLeft, dRight, downloadImg } from "../../constants/import";
import { FaDotCircle } from "react-icons/fa";

const Download = () => {
  const data = [
    "Invoicing Made Easy: A brief intro on creating and tracking invoices with SPINGO.",
    "Smart Inventory Management: Details on inventory tracking, including AI-powered expiry management.",
    "Accounting Simplified: Information on how SPINGO simplifies bookkeeping.",
    "Mobile Management: Highlighting the convenience of the mobile app for on-the-go business management.",
  ];

  return (
    <section className="relative mt-24 w-full bg-faint px-0 pb-10 lg:py-16">
      <div className="container flex-row items-center justify-center lg:flex">
        {/* LeftIcon */}
        <img
          src={dLeft}
          alt="DLEFT"
          className="hide absolute bottom-32 left-0 w-[200px]"
        />

        {/* Image */}
        <div className="z-10 flex flex-row items-center justify-center lg:flex-col">
          <img
            src={downloadImg}
            alt="Download"
            className="mb-5 w-full lg:mb-0"
          />
        </div>
        {/* Desc */}
        <div className="w-full pl-4 lg:w-[50%] lg:pl-10">
          <h2 className="mb-2 text-xl font-medium text-black lg:text-4xl">
            Experience the Future of Business Management
          </h2>
          <p className="mb-0 text-sm font-light tracking-wide text-[#969696] lg:mb-6 lg:text-lg">
            Elevate Your Business Efficiency with a Tailored Walkthrough
          </p>
          {/* Listings */}
          <div className="mb-3 text-xs tracking-wide lg:text-base">
            {data.map((item) => (
              <div key={item} className="flex flex-row items-start py-2">
                <FaDotCircle size={15} className="text-primary" />
                <p className="ml-3 font-medium text-black">{item}</p>
              </div>
            ))}
          </div>
          {/* Button */}
          {/* <div className=" w-full mt-5"> */}
          <Button text="Download Mobile App" onPress={() => {}} />
          {/* </div> */}
        </div>
        {/* Right Icon */}
        <img
          src={dRight}
          alt="DRIGHT"
          className="hide absolute right-0 top-12 w-[80px]"
        />
      </div>
    </section>
  );
};

export default Download;
