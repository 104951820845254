import React from "react";
interface ButtonProps {
  text: string;
  onPress: any;
  outlined?: boolean;
  width?: string;
  white?: boolean;
  size?: "xs" | "sm" | "base" | "lg" | "xl" | "2xl";
}

const Button: React.FC<ButtonProps> = ({
  outlined,
  text,
  onPress,
  width,
  white,
  size = "base",
}) => {
  return (
    <button
      onClick={() => onPress()}
      className={`${
        outlined
          ? "bg-white text-primary"
          : white
            ? "bg-white text-primary"
            : "bg-primary text-white"
      } ${
        width ? width : "w-auto"
      } my-2 flex cursor-pointer flex-row items-center justify-center rounded-full px-[2em] py-[1.25em] text-center font-extralight text-${size} border border-primary`}
    >
      {text}
    </button>
  );
};

export default Button;
