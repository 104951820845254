import { ComingSoon } from "../components/Layouts";
import React from "react";

const Contact = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default Contact;
