import { AboutUs } from "../../constants/import";
import React from "react";
import { GrLinkNext } from "react-icons/gr";

const AboutHome = () => {
  return (
    <section className="relative mt-24 w-full">
      <img
        src={AboutUs}
        alt="About US"
        className="h-[55vh] w-full object-cover lg:min-h-[700px]"
      />
      {/* Absolute Card */}
      <div className="flex w-full flex-row items-center justify-center">
        <div className="bottom-10 -mt-28 w-[clamp(300px,85vw,434px)] rounded-2xl bg-white px-8 py-5 shadow-md drop-shadow-md lg:absolute lg:right-10 lg:mt-0">
          <h3 className="heading">OUR STORIES</h3>
          <p className="text">
            Explore our journey from idea to innovation, customer success
            stories, behind-the-scenes insights, and community impact at Spingo.
          </p>
          <p className="text">
            Dive into our fascinating journey, where we&#39;ve grown from a
            simple idea to a trusted leader in our industry
          </p>
          <div className="mt-3 flex flex-row items-center justify-end">
            <button className="flex flex-row items-center text-sm font-medium text-primary sm:text-base">
              Learn More <GrLinkNext className="ml-2 text-primary" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHome;
