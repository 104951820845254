import { m } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { NavLink, useLocation } from "react-router-dom";
import { menu_items } from "../../constants/data";
import { logo } from "../../constants/import";
import { CloseButton } from "../Generic";
import Accordion from "./Accordion";
import { NavDropDownItems } from "./NavMenuItems";

interface IMobileNavMenu {
  isMenuOpen: boolean;
  handleCloseMobileMenu: () => void;
  openModal: () => void;
}

const mobileMenuVariants = {
  open: {
    transition: { staggerChildren: 0.1, delayChildren: 0.2 },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const menuItemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: [0.25, 0.46, 0.45, 0.94],
    },
  },
  closed: {
    opacity: 0,
    y: 50,
    transition: {
      duration: 0.2,
    },
  },
};

export default function MobileNavMenu({
  isMenuOpen,
  handleCloseMobileMenu,
  openModal,
}: IMobileNavMenu) {
  const [expanded, setExpanded] = useState<false | number>(false);
  const { pathname } = useLocation();
  const prevLocation = useRef(pathname);

  const handleOpenModal = () => {
    handleCloseMobileMenu();
    openModal();
  };

  useEffect(() => {
    if (prevLocation.current !== pathname) {
      handleCloseMobileMenu();
    }
  }, [pathname, handleCloseMobileMenu]);

  return createPortal(
    <div className="fixed z-[1001]">
      <m.div
        onClick={handleCloseMobileMenu}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="backdrop"
      />
      <m.div
        initial={{ translate: "-100%" }}
        animate={{ translate: "0%" }}
        exit={{ translate: "-100%", transition: { delay: 0.5 } }}
        className="fixed left-0 top-0 z-10 h-screen w-full max-w-sm bg-white p-10 lg:hidden"
      >
        <div className="mobileNavHead mb-20 flex items-center justify-between">
          <div className="">
            <img src={logo} alt="logo" className="w-20" />
          </div>
          <CloseButton onClick={handleCloseMobileMenu} />
        </div>

        <m.ul
          variants={mobileMenuVariants}
          initial="closed"
          animate={isMenuOpen ? "open" : "closed"}
          className="flex flex-col justify-start pb-3 pt-2"
        >
          {menu_items.map((item, index) => (
            <m.li key={index} variants={menuItemVariants}>
              {"dropDownItems" in item ? (
                <Accordion
                  expanded={expanded}
                  setExpanded={setExpanded}
                  i={index}
                  title={item.title}
                >
                  <NavDropDownItems menuItem={item} />
                </Accordion>
              ) : (
                <NavLink
                  to={`${item.link}`}
                  className={({ isActive }) =>
                    `flex items-center justify-between py-4 text-xl font-medium capitalize ${
                      isActive ? "text-primary" : ""
                    }`
                  }
                >
                  <span className="flex-1">{item.title}</span>
                </NavLink>
              )}
            </m.li>
          ))}
        </m.ul>

        <button
          onClick={handleOpenModal}
          className="block w-full rounded-full border border-primary px-12 py-3 font-medium capitalize text-primary transition-transform active:scale-95"
        >
          {"sign up"}
        </button>
      </m.div>
    </div>,
    document.getElementById("portal")!,
  );
}
