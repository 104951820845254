import { SlArrowDown } from "react-icons/sl";
import { NavLink } from "react-router-dom";

interface INavMenuItems {
  menuItem: {
    title: string;
    link: string;
    dropDownItems?: {
      icon?: () => React.JSX.Element;
      title: string;
      description: string;
      link: string;
    }[];
  };
}

export default function NavMenuItems({ menuItem }: INavMenuItems) {
  if ("dropDownItems" in menuItem) {
    return (
      <div className="group relative">
        <NavLink
          to={menuItem?.link || ""}
          className={({ isActive }) => {
            return `nav-menu__item flex items-center gap-2 ${
              isActive ? "text-primary" : ""
            }`;
          }}
        >
          {menuItem.title}
          <SlArrowDown
            className="transition-transform group-hover:rotate-180"
            size={12}
          />
        </NavLink>
        <div className="pointer-events-none absolute top-12 w-[400px] translate-y-6 rounded-xl border border-gray-200 bg-white p-6 opacity-0 duration-200 before:absolute before:-top-6 before:left-0 before:h-8 before:w-full group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100">
          <NavDropDownItems menuItem={menuItem} />
        </div>
      </div>
    );
  } else {
    return (
      <NavLink
        to={`/${menuItem.link}`}
        className={({ isActive }) =>
          `nav-menu__item duration-150 hover:scale-95 ${
            isActive ? "text-primary" : ""
          }`
        }
      >
        {menuItem.title}
      </NavLink>
    );
  }
}

export function NavDropDownItems({ menuItem }: INavMenuItems) {
  return (
    <ul className="">
      {" "}
      {menuItem.dropDownItems?.map((item) => (
        <li key={item.title}>
          <NavLink
            to={item.link}
            className="group/subdir mb-2 block rounded-md p-2 last:mb-0"
          >
            <div className="flex items-center gap-2">
              {item?.icon && <item.icon />}
              <div className="">
                <h4 className="mb-1 text-sm font-medium">{item.title}</h4>
                <p className="text-xs text-spingoGray">{item.description}</p>
              </div>
            </div>
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
