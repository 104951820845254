import { appStore, googleStore } from "../../constants/import";
import { FLeftLine, fmobile } from "../../constants/imports/features";

import React from "react";
import { m } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FeatureDownload = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger animations once
    threshold: 0.5, // Trigger animation when 50% of the component is visible
  });

  return (
    <div
      ref={ref}
      className="container relative grid w-full flex-col justify-center bg-faint lg:flex lg:flex-row-reverse"
    >
      {/* Image */}
      <div>
        <img
          src={fmobile}
          alt="fmobile"
          className="-mt-32 mb-10 w-[300px] lg:-mt-52 lg:mb-0"
        />
      </div>
      <m.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
        transition={{ duration: 0.8 }}
        className="z-50 flex w-full flex-col justify-center lg:w-[48%]"
      >
        <h1 className="z-50 text-xl font-semibold text-black lg:text-3xl">
          Access SPINGO on-the-go
        </h1>
        <p className="mb-8 mt-2 text-xs text-black lg:text-sm">
          Download our mobile app from the App Store for iOS or Google Play
          Store for Android. Manage your business efficiently, wherever you are.
        </p>
        {/* Download From Stores Button */}
        <div className="">
          {/* Google Store */}
          <img
            alt="Google Store"
            src={googleStore}
            className="z-50 mb-2 w-[200px] cursor-pointer lg:w-[160px]"
          />
          <img
            alt="app Store"
            src={appStore}
            className="z-50 w-[200px] cursor-pointer lg:w-[160px]"
          />
        </div>
      </m.div>
      {/* Left Icon */}
      <m.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
        transition={{ duration: 0.8 }}
        className="relative"
      >
        <img
          src={FLeftLine}
          alt="DLeft"
          className="absolute bottom-10 left-0 w-[80px] lg:w-[120px]"
        />
      </m.div>
    </div>
  );
};

export default FeatureDownload;
