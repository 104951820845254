import {
  SlSocialLinkedin,
  SlSocialFacebook,
  SlSocialTwitter,
  SlSocialInstagram,
  SlSocialYoutube,
} from "react-icons/sl";
import SalesIcon from "../assets/nav/SalesIcon";
import { ExpensesIcon, InventoryIcon } from "../assets/nav";

const contact = [
  "Help Center",
  "FAQs",
  "Tutorials",
  "Troubleshooting",
  "Contact Support",
];
const company = [
  { title: "Home", link: "" },
  { title: "About Us", link: "about-us" },
  { title: "Features", link: "features" },
  { title: "Careers", link: "careers" },
  { title: "Contact", link: "contact-us" },
];
const resources = [
  "Blogs",
  "Privacy Policy",
  "Terms of Services",
  "Disclaimer",
];
const subContact = ["Phone: +234 8145143613", "Email: info@spingo.app"];
const contactAddress = ["+234 814514361", "info@spingo.app"];

const menu_items = [
  { title: "Home", link: "" },
  {
    title: "Products",
    dropDownItems: [
      {
        icon: InventoryIcon,
        title: "Inventory",
        description: "Effortlessly track stock levels and manage inventory.",
        link: "/products#inventory",
      },
      {
        icon: ExpensesIcon,
        title: "Expenses",
        description: "Manage your finances with ease.",
        link: "/products#expenses",
      },
      {
        icon: SalesIcon,
        title: "Sales",
        description: "Boost your revenue and sales performance.",
        link: "/products#sales",
      },
    ],
    link: "/products",
  },
  {
    title: "Services",
    dropDownItems: [
      {
        title: "Training & Onboarding",
        description:
          "Equip your team with the knowledge and skills they need to leverage Spingo effectively through personalized training sessions.",
        link: "/services",
      },
      {
        title: "Security & Compliance",
        description:
          "Ensure the security and compliance of your data and operations with our robust security measures and compliance solutions.",
        link: "/services#security-compliance",
      },
      {
        title: "Integration Services",
        description:
          "Seamlessly integrate Spingo with other tools and platforms to enhance functionality and efficiency.",
        link: "/services#integration",
      },
    ],
    link: "/services",
  },
  { title: "About Us", link: "about-us" },
  //   { title: "Features", link: "features" },
  { title: "Contact", link: "contact-us" },
];
const socialData = [
  { Icon: SlSocialFacebook, link: "https://www.spingo.app/" },
  { Icon: SlSocialTwitter, link: "https://www.spingo.app/" },
  {
    Icon: SlSocialLinkedin,
    link: "https://www.linkedin.com/company/spingo-app",
  },
  { Icon: SlSocialInstagram, link: "https://www.instagram.com/spingohq" },
  { Icon: SlSocialYoutube, link: "https://www.spingo.app/" },
];

const moreLinks = [
  {
    name: "pricing",
    path: "/pricing",
  },
  { name: "accesibility", path: "/accessibility" },

  { name: "sitemap", path: "/sitemap" },
];

export {
  contact,
  company,
  resources,
  subContact,
  socialData,
  contactAddress,
  menu_items,
  moreLinks,
};
