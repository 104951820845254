import {
  AccessProducts,
  ExpenseTracking,
  InventoryManagement,
  Line,
  ProductsHero,
  SalesMonitoring,
} from "../components/Products";

export default function Product() {
  return (
    <>
      <ProductsHero />
      <InventoryManagement />
      <ExpenseTracking />
      <SalesMonitoring />

      <Line />
      <AccessProducts />
    </>
  );
}
