import React from "react";
import { dOne, dTwo, dThree, dFour } from "../../constants/imports/about";

const DiscoverBluePrint = () => {
  return (
    <section className="container mt-24">
      {/* Header */}
      <div className="flex w-full flex-col items-start justify-between lg:flex-row">
        {/* Col 1 */}
        <div className="w-full lg:w-[25%]">
          <h2 className="text-black">
            Discover our{" "}
            <span className="text-gray-600">Business blueprint</span>
          </h2>
          <p className="text-base text-dark lg:mt-1">Our roadmap to success.</p>
        </div>
        {/* COl 2 */}
        <div className="w-full lg:w-[73%]">
          <p className="mt-2 text-base text-dark">
            Welcome to Spingo&#39;s business playbook, where we unveil the
            strategies and principles that drive our success. Explore our
            guiding philosophies, methodologies, and best practices that shape
            every aspect of our operations. Gain insights into how we innovate,
            collaborate, and deliver value to our customers.
          </p>
        </div>
      </div>
      {/* Gallery */}
      <div className="mt-5 w-full">
        {/* Top */}
        <img src={dOne} alt="Gallery" className="block w-full" />
        <img src={dTwo} alt="Gallery" className="my-3 block w-full lg:hidden" />
        <div className="my-5 flex w-full flex-row justify-between gap-3 lg:gap-5">
          <img src={dTwo} alt="Gallery" className="hide w-full lg:w-[50%]" />
          <img src={dThree} alt="Gallery" className="w-[48%] lg:w-[25%]" />
          <img src={dFour} alt="Gallery" className="w-[48%] lg:w-[25%]" />
        </div>
      </div>
    </section>
  );
};

export default DiscoverBluePrint;
