import React from 'react'
import { accessSectionBg,accessSpingoMobile } from '../../assets/products'
import { appStore, googleStore } from '../../constants/import'
export default function AccessProducts() {
  return (
    <section className='relative aspect-[3]'>
<img src={accessSectionBg} alt="background" aria-hidden="false" className='size-full object-cover absolute -z-[1]' />
<div className="container">
    <div className="flex md:gap-8 flex-col-reverse items-center  md:flex-row">
        <div className=" py-10 lg:py-16 text-center md:text-left">
            <h2>Access SPINGO on-the-go</h2>
            
            
            <p className='text-sm sm:text-base md:text-lg lg:text-xl mt-8'>Download our mobile app from the App Store for iOS or Google Play Store for Android. Manage your business efficiently, wherever you are.</p>
            <div className="flex flex-col  items-center md:items-start mt-8 gap-4">
            {/* Google Store */}
            <div>
              <img
                alt="Google Store"
                src={googleStore}
                className="z-50 w-[clamp(150px,23vw,200px)] cursor-pointer"
              />
            </div>
            <div>
              <img
                alt="App Store"
                src={appStore}
                className="z-50 w-[clamp(150px,23vw,200px)] cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div className="  w-full sm:w-9/12 md:w-[45%] relative flex-shrink-0 h-96 ">
            <img src={accessSpingoMobile} alt="app preview" className='absolute bottom-0 w-full max-w-sm md:max-w-xl' />
        </div>
    </div>

</div>


    </section>
  )
}
