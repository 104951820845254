export default function InventoryIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.65 2.5H7.35C5.36178 2.5 3.75 4.11177 3.75 6.1V23.9C3.75 25.8882 5.36178 27.5 7.35 27.5H22.65C24.6382 27.5 26.25 25.8882 26.25 23.9V6.1C26.25 4.11178 24.6382 2.5 22.65 2.5Z"
        stroke="#007BFF"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
      <path
        d="M21.25 8.75H18.75"
        stroke="#007BFF"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
      <path
        d="M21.25 21.25H18.75"
        stroke="#007BFF"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
      <path
        d="M3.75 15H26.25"
        stroke="#007BFF"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </svg>
  );
}
