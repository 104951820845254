import { sLine, subscribeImg } from "../../constants/import";

import React from "react";
import { FiSend } from "react-icons/fi";
import { GrLinkNext } from "react-icons/gr";

const Subscribe = () => {
  return (
    <section className="relative mt-24 py-16">
      {/* Line */}
      <img
        src={sLine}
        alt="Sline"
        className="hide absolute right-0 top-0 max-h-[970px] object-contain"
      />
      <div className="container grid flex-row items-center justify-start gap-y-7 lg:flex lg:gap-y-0">
        {/* Desc */}
        <div className="z-10 w-full lg:mr-20 lg:w-[45%]">
          <h2 className="">Stay Updated with SPINGO</h2>
          <p className="mt-5">
            Get the latest features, tips, and exclusive offers directly to your
            inbox. Simplify your operations and keep ahead of the curve.
          </p>
          {/* Input Field*/}
          <div className="relative my-10 flex h-16">
            <input
              placeholder="Enter Email"
              className="flex-1 rounded-md border border-cyan-300 bg-white px-6 text-base font-medium placeholder:text-spingoGray focus:outline-primary"
            />
            <button className="ms-3 flex h-full items-center justify-center rounded-xl bg-primary px-6">
              <FiSend color="white" className="text-4xl" />
            </button>
          </div>
          {/* Bottom Text */}
          <p className="mb-5 lg:mb-0">
            Subscribe now and receive a 10% discount <br /> on your next SPINGO
            subscription! <br />
            Stay updated and save on essential business tools{" "}
          </p>
        </div>
        {/* Image */}
        <div className="relative z-20">
          <img src={subscribeImg} alt="Download" className="w-full" />
          {/* Absolute Card */}
          <div className="group absolute bottom-0 left-1/2 w-10/12 max-w-xs -translate-x-1/2 translate-y-1/2 rounded-lg bg-primary px-4 py-6 sm:left-auto sm:right-4 sm:w-1/2 sm:max-w-sm sm:translate-x-0 md:max-w-none lg:left-1/2 lg:w-8/12 lg:-translate-x-1/2 lg:py-8">
            <p className="text-sm text-white md:text-base">
              See SPINGO in action with a personalized demo. Get guided
              insights, tailored solutions, and answers to your questions.
            </p>
            <div className="mt-3 flex items-center gap-4 md:gap-8">
              <button className="flex-1 rounded-full bg-white px-4 py-3 text-sm text-primary lg:text-base">
                Demo
              </button>
              <button className="flex flex-1 flex-row items-center text-sm text-white lg:text-base">
                Learn More{" "}
                <GrLinkNext
                  color="white"
                  className="ml-4 transition-transform group-hover:translate-x-1"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
