import { useContext } from "react";
import { createPortal } from "react-dom";
import QRCode from "react-qr-code";
import { CloseButton } from "../components/Generic";
import { AppDownloadLinks } from "../components/Home/Hero";
import Modal from "../components/Layouts/Modal";
import { SignUpContext } from "../components/Layouts/NavBar";

export default function SignUp() {
  const setIsModalOpen = useContext(SignUpContext);

  const handleCloseModal = () => {
    if (setIsModalOpen) {
      setIsModalOpen(false);
      document.body.style.overflow = "auto";
    }
  };

  return createPortal(
    <Modal>
      <div className="relative flex w-full flex-col">
        <div className="absolute -right-2 -top-2">
          <CloseButton onClick={handleCloseModal} />
        </div>
        <div className="p-4 text-center">
          <h2 className="text-lg font-medium text-primary sm:text-2xl">
            Scan to download the SpinGo and get started
          </h2>

          <div className="qrcode__image my-14 flex w-full justify-center">
            <QRCode value="downlink is here" />
          </div>
        </div>

        {/* Modal QRcode */}
      </div>{" "}
      <div className="flex w-full items-center justify-center gap-2 lg:gap-8">
        <AppDownloadLinks />
      </div>
    </Modal>,
    document.getElementById("portal")!,
    "signupmodal",
  );
}
