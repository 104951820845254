import React from "react";
import { m } from "framer-motion"; // Import motion from Framer Motion
import { FaDotCircle } from "react-icons/fa";
import { Button } from "../Generic";
import {
  fexpenses,
  finventory,
  fsales,
} from "../../constants/imports/features";

const FeatureContents = () => {
  const managementProcesses = [
    {
      title: "Streamlined Inventory Management",
      image: finventory,
      desc: "Inventory management refers to the process of overseeing and controlling the flow of goods into and out of a business. It involves tracking inventory levels, monitoring stock movements, and optimizing stock replenishment.",
      lists: [
        "Optimized Stock Levels: Ensure you have the right amount of inventory on hand to meet customer demand without overstocking.",
        "Reduced Holding Costs: Minimize storage costs and the risk of obsolete inventory by maintaining optimal stock levels.",
        "Improved Efficiency: Streamline operations with real-time inventory tracking, reducing the time and resources spent on manual inventory management tasks.",
        "Enhanced Customer Satisfaction: Avoid stockouts and backorders, ensuring customers can purchase products when they need them, leading to increased customer satisfaction and loyalty.",
      ],
    },
    {
      title: "Expense Tracking",
      image: fexpenses,
      desc: "Expense monitoring involves tracking and managing business expenses to control costs, improve financial efficiency, and maximize profitability. It includes monitoring spending, categorizing expenses, and identifying cost-saving opportunities.",
      lists: [
        "Cost Control: Monitor and analyze expenses to identify areas where costs can be reduced or optimized, improving overall financial efficiency.",
        "Budget Management: Set and track budgets for different expense categories, ensuring spending stays within allocated limits.",
        "Improved Cash Flow: Maintain visibility into cash flow by monitoring expenses and identifying opportunities to optimize spending and increase revenue.",
        "Financial Transparency: Gain a clear understanding of where money is being spent in the business, allowing for better financial planning and decision-making.",
      ],
    },
    {
      title: "Sales Monitoring",
      image: fsales,
      desc: "Sales tracking involves monitoring and analyzing sales data to understand performance, identify trends, and make informed business decisions. It includes tracking sales revenue, analyzing sales metrics, and forecasting future sales.",
      lists: [
        "Insightful Decision-Making: Gain valuable insights into sales performance, customer behavior, and market trends to make data-driven decisions that drive growth.",
        "Identify Opportunities: Spot emerging trends and opportunities for growth by analyzing sales data and customer purchasing patterns.",
        "Evaluate Marketing Effectiveness: Measure the impact of marketing campaigns and promotions on sales performance to optimize marketing strategies and maximize ROI.",
        "Forecasting Accuracy: Use historical sales data to forecast future sales and inventory needs accurately, minimizing stockouts and overstock situations.",
      ],
    },
  ];

  return (
    <div>
      {managementProcesses.map((process, index) => (
        <m.div
          key={index}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: index * 0.2 }}
          className={`relative grid w-full bg-faint px-0 py-10 pb-10 lg:flex lg:px-28 ${
            index === 1 ? "flex-row-reverse" : "flex-row"
          } items-center justify-center`}
        >
          {/* Image */}
          <m.div
            initial={{ opacity: 0, x: index === 1 ? 100 : -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: index * 0.2 }}
            className="z-10 flex flex-row items-center justify-center lg:flex-col"
          >
            <img
              src={process.image}
              alt={process.desc}
              className="mb-5 w-[100%] lg:mb-0 lg:w-[400px]"
            />
          </m.div>
          <m.div
            initial={{ opacity: 0, x: index === 1 ? -100 : 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: index * 0.2 }}
            className={`w-full lg:w-[50%] ${
              index === 1 ? "lg:pr-20" : "lg:pl-20"
            } px-8`}
          >
            <h1 className="mb-3 text-xl font-medium text-black underline">
              {process.title}
            </h1>
            <p className="mb-5 text-sm font-light text-[#969696]">
              {process.desc}
            </p>
            {/* Listings */}
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: index * 0.2 + 0.4 }}
              className="mb-3"
            >
              {process.lists.map((item) => (
                <div key={item} className="flex flex-row items-start py-2">
                  <FaDotCircle size={15} className="text-primary" />
                  <p className="ml-3 text-xs font-medium text-black">{item}</p>
                </div>
              ))}
            </m.div>
            {/* Button */}
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: index * 0.2 + 0.6 }}
              className="mt-5 w-full lg:w-[200px]"
            >
              <Button text="Get Started" onPress={() => {}} />
            </m.div>
          </m.div>
        </m.div>
      ))}
    </div>
  );
};

export default FeatureContents;
