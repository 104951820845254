import {
  abubakar,
  azeezah,
  ibrahim,
  muhammad,
  rahma,
  seyanu,
} from "../../constants/imports/team";

import React from "react";

const data = [
  { name: "Ibrahim Dauda", role: "Senior Software Engineer", photo: ibrahim },
  { name: "Rahama Sani", role: "Product Manager", photo: rahma },
  { name: "Seyanu Ademuwa", role: "Senior Product Designer", photo: seyanu },
  { name: "Azeezah", role: "Product designer", photo: azeezah },
  { name: "Abubakar Sadeeq", role: "Business Development", photo: abubakar },
  { name: "Muhammad Ibrahim", role: "Backend Engineer", photo: muhammad },
];
const Team = () => {
  return (
    <section className="container mt-24">
      {/* Header */}
      <div className="mb-5">
        <h2 className="text-black-600">Our Team</h2>
        <h3 className="text-lg font-bold text-spingoGray lg:text-2xl">
          Leaders and Executives
        </h3>
        <p className="mb-4 text-base text-spingoGray lg:mb-0">
          Meet the visionaries behind Spingo
        </p>
      </div>
      {/* Pictures */}
      <div className="grid grid-cols-1 items-center gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {data.map((item, index) => (
          <div key={index} className="my-0 lg:my-4">
            <img
              src={item.photo}
              className="aspect-[97/168] w-full rounded-md object-cover sm:max-w-xs"
              alt="Team"
            />
            <div className="mt-2">
              <h1 className="text-md text-center font-medium text-black lg:text-left lg:text-lg lg:font-semibold">
                {item.name}
              </h1>
              <p className="text-center text-sm font-extralight text-gray-500 lg:text-left">
                {item.role}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;
