export default function SalesIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 27.5H17.5C23.75 27.5 26.25 25 26.25 18.75V11.25C26.25 5 23.75 2.5 17.5 2.5H12.5C6.25 2.5 3.75 5 3.75 11.25V18.75C3.75 25 6.25 27.5 12.5 27.5Z"
        stroke="#F39C12"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.625 9.47461V10.7246C20.625 11.7496 19.7875 12.5996 18.75 12.5996H11.25C10.225 12.5996 9.375 11.7621 9.375 10.7246V9.47461C9.375 8.44961 10.2125 7.59961 11.25 7.59961H18.75C19.7875 7.59961 20.625 8.43711 20.625 9.47461Z"
        stroke="#F39C12"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1701 17.5H10.1846"
        stroke="#F39C12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9939 17.5H15.0083"
        stroke="#F39C12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8181 17.5H19.8325"
        stroke="#F39C12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1701 21.875H10.1846"
        stroke="#F39C12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9939 21.875H15.0083"
        stroke="#F39C12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8181 21.875H19.8325"
        stroke="#F39C12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
