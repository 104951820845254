import {
  accessLeftLine,
  accessRightLine,
  appStore,
  googleStore,
} from "../../constants/import";

import React from "react";

const AccessSpingo = () => {
  return (
    <section className="relative mx-auto w-full bg-primary py-28">
      <div className="container flex flex-row items-center justify-center">
        {/* Right Icon */}
        <img
          src={accessRightLine}
          alt="DRIGHT"
          className="absolute right-1 top-0 w-[60px] lg:top-2 lg:w-[120px]"
        />
        <div className="z-50 flex flex-col items-center justify-center sm:w-10/12">
          <h2 className="z-50 text-center text-3xl font-medium text-white lg:text-4xl">
            Access SPINGO on-the-go
          </h2>
          <p className="mb-8 mt-2 text-center text-white">
            Download our mobile app from the App Store for iOS or Google Play
            Store for Android. Manage your business efficiently, wherever you
            are.
          </p>
          {/* Download From Stores Button */}
          <div className="flex flex-col items-center justify-center gap-2 lg:flex-row lg:gap-8">
            {/* Google Store */}
            <div>
              <img
                alt="Google Store"
                src={googleStore}
                className="z-50 w-[clamp(150px,27vw,200px)] cursor-pointer"
              />
            </div>
            <div>
              <img
                alt="App Store"
                src={appStore}
                className="z-50 w-[clamp(150px,27vw,200px)] cursor-pointer"
              />
            </div>
          </div>
        </div>
        {/* Left Icon */}
        <img
          src={accessLeftLine}
          alt="DLeft"
          className="absolute bottom-10 left-0 w-[80px] lg:w-[120px]"
        />
      </div>
    </section>
  );
};

export default AccessSpingo;
