import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const GetStarted = () => {
  const data = [
    {
      title: "Inventory",
      headerOne: "Seamless inventory tracking",
      one: [
        "Scan barcodes or manually input items.",
        "Categorize inventory items for efficient organization.",
        "Centralize all inventory data for streamlined access.",
      ],
      headerTwo: "Real-time inventory insights and report",
      two: [
        "Monitor stock levels and inventory movements.",
        "Receive notifications for low stock or replenishment needs.",
        "Generate detailed inventory reports anytime, anywhere.",
      ],
    },
    {
      title: "Sales",
      headerOne: "Streamlined sales recording",
      one: [
        "Record sales transactions effortlessly",
        "Classify sales by product, category, or customer.",
      ],
      headerTwo: "Real-time sales insights and Sales reports",
      two: [
        "Monitor sales performance in real-time.",
        "Analyze sales trends and patterns.",
        "Generate comprehensive sales reports instantly.",
      ],
    },
    {
      title: "Expenses",
      headerOne: "Effortless expense capture",
      one: [
        "Easily upload receipts or expense documents.",
        "Categorize expenses for efficient organization.",
      ],
      headerTwo: "Real-time expense insights and Expense reports",
      two: [
        "Monitor sales performance in real-time.",
        "Analyze sales trends and patterns.",
        "Generate comprehensive sales reports instantly.",
      ],
    },
  ];

  return (
    <section className="mt-28">
      <div className="container bg-white">
        <h2 className="mb-2 font-medium text-black lg:mb-1">
          Getting Started with Spingo
        </h2>
        <p className="text-sm font-light text-dark">
          Learn the basics, explore advanced features, and make the most out of
          your Spingo experience.
        </p>
        <div className="w-full">
          <div className="my-2 py-0 lg:py-5">
            {data.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;

interface ItemProps {
  item: any;
}

const Item: React.FC<ItemProps> = ({ item }) => {
  const [onShowDetails, setOnShowDetails] = useState(false);

  return (
    <div className="shadow-xs my-5 w-full rounded-lg border border-gray-200 bg-white p-5 px-4 lg:px-12">
      <div
        className="flex w-full cursor-pointer items-center justify-between"
        onClick={() => setOnShowDetails((prev) => !prev)}
      >
        <h2 className="text-sm md:text-xl lg:text-2xl">{item.title}</h2>
        {/* Icon */}
        <div>
          {onShowDetails ? (
            <MdKeyboardArrowUp className="size-4 text-black md:size-5 lg:size-6" />
          ) : (
            <MdKeyboardArrowDown className="size-4 text-black md:size-5 lg:size-6" />
          )}
        </div>
      </div>
      {onShowDetails && (
        <div className="flex w-full flex-col items-start pt-4 lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:mb-0 lg:w-[45%]">
            <h4 className="mb-3 text-sm font-medium text-primary">
              {`1. ${item.headerOne}`}
            </h4>
            {item.one.map((text: string, index: number) => (
              <div key={index} className="flex flex-row items-center py-1">
                <GoDotFill size={12} className="text-black" />
                <p className="ml-2 text-xs font-medium text-black">{text}</p>
              </div>
            ))}
          </div>
          <div className="w-full lg:w-[45%]">
            <h4 className="mb-3 text-sm font-medium text-primary">
              {`2. ${item.headerTwo}`}
            </h4>
            {item.two.map((text: string, index: number) => (
              <div key={index} className="flex flex-row items-center py-1">
                <GoDotFill size={12} className="text-black" />
                <p className="ml-2 text-xs font-medium text-black">{text}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
