import { ProductsSections } from "../../constants/imports/products";
import ProductsVideoLayout from "../Layouts/ProductsSectionLayout";

export default function SalesMonitoring() {
  return (
    <section className="mt-24" id="sales">
      <ProductsVideoLayout section={ProductsSections[2]} index={2} />
    </section>
  );
}
