import React from "react";
import { m } from "framer-motion";

const AboutHeader = () => {
  return (
    <m.section
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="container pt-10 lg:pt-20"
    >
      <p className="text">About Us</p>

      <h1 className="w-full text-2xl font-medium sm:text-3xl lg:w-[80%] lg:text-5xl">
        Discover the story behind <span className="text-primary">Spingo</span>{" "}
        and how we&#39;re revolutionizing business management.
      </h1>
      <p className="text mt-6 lg:mt-12">
        See how Spingo is redefining business management and empowering
        organizations to thrive in the digital age.
      </p>
    </m.section>
  );
};

export default AboutHeader;
