import { Button } from "../../components/Generic";
import { comingSoon, mobileHeroBg } from "../../constants/import";
import React from "react";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
  const navigate = useNavigate();
  return (
    <div className="relative mb-10 h-[70vh] px-10 pb-8 pt-14 lg:h-[100vh] lg:px-20 lg:pb-20 lg:pt-32">
      <img
        src={comingSoon}
        alt="Hero Background "
        className="absolute left-0 top-0 -z-30 hidden h-full w-full lg:block"
      />
      <img
        src={mobileHeroBg}
        alt="Hero Background "
        className="absolute left-0 top-0 -z-30 block h-full w-full object-cover lg:hidden"
      />
      <div className="w-full lg:w-[40%]">
        <h1 className="text-center text-xl font-semibold text-black lg:text-left lg:text-3xl">
          Coming Soon!
        </h1>
        <p className="mt-4 text-xs text-black lg:text-sm">
          Page Under Construction...
        </p>
        {/* BUttons */}
        <div className="flex w-full flex-col items-center justify-between pt-10 lg:flex-row">
          <div className="w-full lg:w-[180px]">
            <Button text="Back to Home" onPress={() => navigate("/")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
