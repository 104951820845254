import {line } from '../../assets/products/'



export default function Line() {
  return (
    <div>
        <img src={line} aria-hidden="true" alt='' className='w-full' />
        </div>
  )
}
