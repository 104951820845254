import {
  aboutOneImg,
  aboutTwoImg,
  aboutLeft,
  aboutRight,
} from "../../constants/imports/about";

import React from "react";
import { Button } from "../Generic";
import { rahma } from "../../constants/imports/team";
const CompanyOverview = () => {
  return (
    <section className="container relative mt-24 flex flex-col items-start justify-between lg:flex-row">
      {/* LeftIcon */}
      <img
        src={aboutLeft}
        alt="DLEFT"
        className="hide absolute -left-2 bottom-56 w-[200px]"
      />
      {/* Images Container */}
      <div className="z-50 flex h-[400px] w-[100%] items-start gap-3 lg:h-[550px] lg:w-[60%] lg:gap-6">
        <img
          src={aboutOneImg}
          alt="About One"
          className="h-full w-1/3 object-cover"
        />
        <img
          src={aboutTwoImg}
          alt="About Two"
          className="h-full w-2/3 object-cover"
        />
      </div>
      {/* Company Overview */}
      <div className="z-50 mt-10 w-full bg-white lg:mt-0 lg:w-[35%] lg:pb-52">
        <h2 className="mb-3 text-black-600">Company Overview</h2>
        <p className="mb-2 mt-1 text-base text-dark lg:mb-10 lg:mt-6">
          Welcome to Spingo, where innovation meets efficiency in business
          management. Founded in 2024,{" "}
          <span className="font-medium text-primary">Spingo</span> is on a
          mission to revolutionize how businesses operate, streamlining
          processes and maximizing productivity.
        </p>
        <div className="flex flex-row items-center gap-2 lg:flex-col lg:items-start">
          <Button text="Our Manifesto" onPress={() => {}} />
          {/* Product Manager */}
          <div className="flex items-center lg:my-8">
            <img
              src={rahma}
              className="h-[25px] w-[25px] rounded-full object-cover lg:h-[60px] lg:w-[60px]"
              alt="Rahma"
            />
            <div className="ml-2">
              <h1 className="text-sm font-semibold lg:text-xl">Rahma Sani</h1>
              <h1 className="text-xs font-medium lg:text-sm">
                Product Manager
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* LeftIcon */}
      <img
        src={aboutRight}
        alt="DRightaboutRight"
        className="hide absolute -right-2 bottom-40 -z-50 w-[200px]"
      />
    </section>
  );
};

export default CompanyOverview;
