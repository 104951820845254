import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layouts/Layout";
import { About, Contact, Features, Home, Products } from "./pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "contact-us",
        element: <Contact />,
      },
      {
        path: "services",
        element: <Contact />,
      },
      {
        path: "about-us",
        element: <About />,
      },
      {
        path: "features",
        element: <Features />,
      },
      {
        path: "products",
        element: <Products />,
      },
    ],
  },
]);
export default router;
