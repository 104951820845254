import { WorksIcon, expenses, inventory, sales } from "../../constants/import";

import React from "react";
import { m } from "framer-motion";

const HowItWorks = () => {
  const data = [
    {
      title: "Inventory Management",
      desc: "Efficiently manage inventory with SPINGO. Track stock levels, get low-stock alerts, and streamline reordering. Sign Up Effortlessly- Quick guide on setting up a SPINGO account",
      img: inventory,
    },
    {
      title: "Expense Tracking",
      desc: "Keep your finances in check with SPINGO expense tracking feature. Easily record and categorize expenses, generate expense reports, and gain insights into your spending habits.",
      img: expenses,
    },
    {
      title: "Sales Monitoring",
      desc: " Gain real-time insights into your sales performance with SPINGO sales monitoring capabilities. Track revenue trends, analyze customer purchasing patterns, and optimize your sales strategy.",
      img: sales,
    },
  ];

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <section className="mt-24 w-full">
      <div className="container">
        {/* Header */}
        <div className="relative flex w-full flex-col items-center justify-center">
          {/* Icon */}
          <img
            src={WorksIcon}
            alt="How it Works"
            className="hh absolute left-44 hidden lg:block"
          />
          {/* Text */}
          <div className="w-full lg:w-[40%]">
            <h2 className="mb-2 text-center">How It Works</h2>
            <p className="text-center text-lg">
              Discover the SPINGO Difference – Smarter Operations, Better
              Results
            </p>
          </div>
        </div>
        {/* Content */}
        <div className="grid w-full gap-7 pt-10 sm:grid-cols-2 lg:grid-cols-3">
          {data.map((item, index) => (
            <m.div
              key={index}
              className="mx-auto w-full max-w-sm rounded-md rounded-tl-[65px] rounded-tr-none bg-white shadow-md"
              initial="hidden"
              whileInView="visible"
              variants={variants}
              viewport={{ once: true, amount: 0.2 }}
            >
              {/* Image */}
              <img
                src={item.img}
                className="w-full rounded-tl-[65px] rounded-tr-none object-cover"
                alt={item.title}
              />
              {/* Desc */}
              <div className="p-4">
                <h1 className="text-md mb-2 text-xl font-medium text-primary underline">
                  {item.title}
                </h1>
                <p className="text-sm text-dark lg:text-base">{item.desc}</p>
              </div>
            </m.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
