import React from "react";
import {
  AccessSpingo,
  Download,
  GetStarted,
  Hero,
  HowItWorks,
  Subscribe,
} from "../components/Home";

const Home = () => {
  return (
    <>
      <Hero />
      <HowItWorks />
      <Download />
      <Subscribe />
      <GetStarted />
      <AccessSpingo />
    </>
  );
};

export default Home;
