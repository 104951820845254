import {
  productsHeroImageLg,
  productsHeroImageSm,
} from "../../assets/products";
import { productHeroIcons } from "../../constants/imports/products";

export default function ProductsHero() {
  return (
    <section className="relative aspect-[3/4] lg:aspect-[837/672]">
      <picture>
        <source srcSet={productsHeroImageLg} media="(min-width: 1024px)" />
        <img
          src={productsHeroImageSm}
          alt="products hero"
          className="absolute -z-10 size-full object-cover"
        />
      </picture>
      <div className="container pt-12 sm:pt-20 md:pt-28 lg:pt-36">
        <div className="sm-px-0">
          <hgroup className="text-center lg:text-left">
            <h1 className="text-2xl font-medium text-black md:text-4xl lg:text-5xl">
              Explore Our Core Features for Seamless Business Management
            </h1>
            <h2 className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl">
              From Inventory to Sales, We've got it all.
            </h2>
          </hgroup>
          <ul className="product-hero__icons mt-8 flex justify-center gap-8 md:mt-20 lg:justify-start lg:gap-14">
            {productHeroIcons.map((icon) => (
              <li key={icon.title}>
                <img
                  src={icon.imgSrc}
                  alt={icon.title}
                  className="mx-auto size-10 rounded-md border border-primary object-cover md:size-24 lg:h-[70px] lg:w-20"
                />
                <h3 className="mt-2 text-center text-xs font-medium capitalize sm:text-sm md:text-base">
                  {icon.title}
                </h3>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
