import { Link } from "react-router-dom";
import {
  contact,
  company,
  resources,
  subContact,
  socialData,
  contactAddress,
  moreLinks,
} from "../../constants/data";
import { logo } from "../../constants/import";

import React from "react";

const Footer = () => {
  return (
    <footer className="container mt-24">
      <div className="grid grid-cols-2 [row-gap:20px] md:grid-cols-4 lg:grid-cols-4">
        {/* Col 1 Company */}
        <div className={"footer-col"}>
          <h3 className="heading">Company</h3>
          {company.map((item, index) => (
            <Link to={`/${item.link}`} key={index} className="text block">
              {item.title}
            </Link>
          ))}
        </div>
        {/* Col 3 Contact  */}
        <div className={"footer-col"}>
          <h3 className="heading text-xl">Support</h3>
          {contact.map((item, index) => (
            <p className="text text-base" key={index}>
              {item}
            </p>
          ))}
        </div>
        {/* Col 3 Resources  */}
        <div className={"footer-col"}>
          <h3 className="heading text-xl">Resources</h3>
          {resources.map((item, index) => (
            <p className="text text-base" key={index}>
              {item}
            </p>
          ))}
        </div>
        {/* Col 4 Addreesss, Logo, COntact Details */}
        <div className="footer-col col-span-full row-start-1 lg:col-start-4">
          {/* About Spingo, SHow LG and Hide Mobile */}
          <div className="block text-sm md:text-base">
            <img src={logo} alt="logo" className="w-20" />
            <div className="mt-4 lg:hidden">
              <SocialIcons />
            </div>
            <p className="mt-4 text-black">
              SPINGO Nigeria Limited, Gwagwalada,
              <br /> FCT, Abuja, Nigeria.
            </p>
            <div className={"mt-6 lg:mt-8"}>
              {subContact.map((item, index) => (
                <p className="text-black" key={index}>
                  {item}
                </p>
              ))}
            </div>
          </div>
          {/* Col 3 Contact Details Hide LG and Show Mobile  */}
          <div className={"hidden"}>
            <h3 className="heading">Contact</h3>
            {contactAddress.map((item, index) => (
              <p className="text" key={index}>
                {item}
              </p>
            ))}
          </div>
        </div>
        {/* Col 5 Pricing, Accessibility, SiteMap (MoreLinks) */}

        <div className="footer-col md:col-start-4 lg:hidden">
          {/* About Spingo, SHow LG and Hide Mobile */}
          <ul className="flex flex-col gap-3">
            <MoreLinks />
          </ul>
        </div>
      </div>
      <div className="hidden w-full border-b border-b-dark py-3 lg:block">
        {/* Social Links */}
        <div className="">
          <SocialIcons />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <p className="flex w-full items-center justify-center py-12 font-normal text-neutral-800 lg:w-auto">
          <span className="me-2 text-3xl sm:text-4xl">©</span>
          <span className="text-sm sm:text-base">
            {" "}
            2024 SPINGO Nigeria Limited. All rights reserved.
          </span>
        </p>
        <ul className="hidden lg:flex">
          <MoreLinks />
        </ul>
      </div>
    </footer>
  );
};

export const SocialIcons = () => {
  return (
    <div className="flex flex-row items-center gap-0.5 lg:gap-4">
      {socialData.map((item, index) => (
        <a
          href={item.link}
          target="_blank"
          key={index}
          className={`${index === 0 ? "" : ""} p-1 lg:p-2`}
          rel="noopener noreferrer"
        >
          <item.Icon className="size-5 text-neutral-600 md:size-6" />
        </a>
      ))}
    </div>
  );
};

function MoreLinks() {
  return (
    <>
      {moreLinks.map((link) => (
        <li key={link.path}>
          <Link
            to={link.path}
            className="px-3 capitalize text-spingoGray transition-transform hover:scale-95"
          >
            {link.name}
          </Link>
        </li>
      ))}
    </>
  );
}

export default Footer;
