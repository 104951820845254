import {
  FeatureContents,
  FeatureDownload,
  FeatureHeader,
} from "../components/Features";
import FeatureLine from "../components/Features/FeatureLine";
import React from "react";

const Features = () => {
  return (
    <>
      <FeatureHeader />
      <FeatureContents />
      <FeatureLine />
      <FeatureDownload />
    </>
  );
};

export default Features;
