import { AccessSpingo } from "../components/Home";
import {
  AboutHeader,
  AboutHome,
  CompanyOverview,
  DiscoverBluePrint,
  Team,
  Career,
  FaqButton,
} from "../components/About";
import React from "react";

const About = () => {
  return (
    <>
      <AboutHeader />
      <AboutHome />
      <CompanyOverview />
      <DiscoverBluePrint />
      <Team />
      <Career />
      <FaqButton />
      <AccessSpingo />
    </>
  );
};

export default About;
