import ProductsVideoLayout from "../Layouts/ProductsSectionLayout";
import { ProductsSections } from "../../constants/imports/products";
import { inventoryLine } from "../../assets/products";
export default function InventoryManagement() {
  return (
    <section className="relative mt-24" id="inventory">
      <img
        src={inventoryLine}
        alt="line"
        className="absolute bottom-0 right-0 hidden lg:block"
      />
      <ProductsVideoLayout section={ProductsSections[0]} index={0} />
    </section>
  );
}
