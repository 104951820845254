import { useRef, useState } from "react";
import { BsPlayCircle } from "react-icons/bs";

interface IVideoPlayer {
  src: string;
}
export default function VideoPlayer({ src }: IVideoPlayer) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoClick = () => {
    isPlaying ? handlePause() : handlePlay();
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  return (
    <div className="relative mx-auto size-full max-w-4xl">
      <video
        ref={videoRef}
        className="h-full w-full rounded-3xl object-cover"
        onClick={handleVideoClick}
        onEnded={handleVideoEnd}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Custom play button */}
      {!isPlaying && (
        <button
          onClick={handlePlay}
          className="absolute inset-0 flex items-center justify-center rounded-3xl bg-black bg-opacity-25"
        >
          <BsPlayCircle size={100} color="white" />
        </button>
      )}
    </div>
  );
}
