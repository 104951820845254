import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

import NavMenuItems from "./NavMenuItems";
import { logo } from "../../constants/import";
import { menu_items } from "../../constants/data";
import {
  AnimatePresence,
  m,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import { Link } from "react-router-dom";
import MobileNavMenu from "./MobileNavMenu";
import SignUp from "../../pages/SignUp";

// const childvariants = {
//   open: {
//     y: 0,
//     opacity: 1,
//     transition: {
//       y: { stiffness: 1000, velocity: -100 },
//     },
//   },
//   closed: {
//     y: 50,
//     opacity: 0,
//     transition: {
//       y: { stiffness: 1000 },
//     },
//   },
// };

const navVisibiltyVariants = {
  visible: {
    y: 0,
  },
  hidden: {
    y: "-100%",
  },
};

type SignUpContextType = Dispatch<SetStateAction<boolean>> | null;

export const SignUpContext = createContext<SignUpContextType>(null);

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    console.log(latest, previous);

    if (latest > previous! && latest > 100) setIsHidden(true);
    else {
      setIsHidden(false);
    }
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleOpenMobileMenu = () => {
    setIsMenuOpen(true);
    document.body.style.overflow = "hidden";
  };
  const handleCloseMobileMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = "auto";
  };
  return (
    <>
      <m.nav
        className="fixed top-0 z-[100] w-full bg-white py-3 shadow-sm drop-shadow-lg"
        variants={navVisibiltyVariants}
        animate={isHidden ? "hidden" : "visible"}
        transition={{ duration: 0.2, ease: "easeInOut" }}
      >
        <div className="container">
          <div className="flex items-center justify-between">
            <div className="flex-shrink-0">
              <Link to="/" className="flex items-center gap-2">
                <img src={logo} alt="website Logo" className="w-20" />
              </Link>
            </div>
            <div className="mx-auto hidden lg:block">
              <div className="flex items-baseline gap-8">
                {menu_items.map((menu_item) => (
                  <NavMenuItems menuItem={menu_item} key={menu_item.title} />
                ))}
              </div>
            </div>
            <div className="ml-0 hidden md:ml-auto md:block lg:ml-0">
              <div className="hidden items-center md:flex">
                <button
                  onClick={handleOpenModal}
                  className="block rounded-full border border-primary px-12 py-3 font-medium capitalize text-primary transition-transform active:scale-95"
                >
                  {"sign up"}
                </button>
              </div>
            </div>
            <div className="menu__toggle___btn ml-2 lg:hidden">
              <button
                onClick={handleOpenMobileMenu}
                className="inline-flex h-9 w-9 flex-col items-center justify-center gap-1 rounded-md p-2 text-gray-400"
                aria-expanded={isMenuOpen}
              >
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </button>
            </div>
          </div>

          {/* Mobile Menu */}

          <AnimatePresence>
            {isMenuOpen && (
              <MobileNavMenu
                openModal={handleOpenModal}
                isMenuOpen={isMenuOpen}
                handleCloseMobileMenu={handleCloseMobileMenu}
              />
            )}
          </AnimatePresence>
        </div>
      </m.nav>
      <AnimatePresence>
        {isModalOpen && (
          <SignUpContext.Provider value={setIsModalOpen}>
            <SignUp />
          </SignUpContext.Provider>
        )}
      </AnimatePresence>
    </>
  );
};

export default NavBar;
