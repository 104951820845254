import React from "react";
import { m } from "framer-motion"; // Import motion from Framer Motion
import { Button } from "../Generic";

import { career } from "../../constants/imports/about";

const Career = () => {
  return (
    <m.section
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="relative mt-24 flex h-[500px] w-full flex-row items-center justify-center"
    >
      <img
        src={career}
        alt="Career"
        className="absolute left-0 top-0 -z-30 h-full w-full object-cover"
      />
      {/* Content */}
      <div className="z-50 flex flex-col items-center justify-center lg:w-[40%]">
        <h1 className="text-center text-xl font-medium text-white lg:text-3xl">
          Careers at SPINGO
        </h1>
        <p className="my-3 px-10 text-center text-sm text-white">
          Explore exciting career opportunities and join our innovative team at
          Spingo today
        </p>
        <Button width="w-[240px]" text="Join Our Team" onPress={() => {}} />
      </div>
    </m.section>
  );
};

export default Career;
