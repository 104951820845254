export default function FaqButton() {
  return (
    <div className="container my-10 flex flex-col items-center justify-center gap-6 lg:flex-col">
      <p className="text-center text-lg">
        Have questions? Get answers in our FAQ section!
      </p>
      <button className="w-56 rounded-full border border-primary bg-white py-4 text-primary lg:bg-primary lg:text-white">
        Explore Faq
      </button>
    </div>
  );
}
