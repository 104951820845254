import { Button } from "../Generic";
import VideoPlayer from "../Generic/VideoPlayer";

interface IProductsVideoLayout {
  section: {
    videoUrl: string;
    sectionBody: {
      title: string;
      definitionTerm: string;
      definitionBody: string;
      List: string[];
    };
  };
  index: number;
}

export default function ProductsVideoLayout({
  section: { videoUrl, sectionBody },
  index,
}: IProductsVideoLayout) {
  const isEven = index % 2 === 0;

  return (
    <div
      className={`container col-start-1 grid grid-cols-1 gap-10 py-10 lg:grid-cols-2 lg:grid-rows-1 lg:gap-20 ${isEven ? "" : ""}`}
    >
      <div
        className={`w-full lg:row-start-1 ${isEven ? "" : "lg:col-start-2"}`}
      >
        <VideoPlayer src={videoUrl} />
      </div>
      <div
        className={`section-body lg:row-start-1 ${isEven ? "" : "lg:col-start-1"}`}
      >
        <h3 className="mb-7 text-lg underline font-semibold sm:text-xl">
          {sectionBody.title}
        </h3>
        <p className="definiton-term mb-2 font-medium">
          {sectionBody.definitionTerm}
        </p>
        <p>{sectionBody.definitionBody}</p>

        <ul className="mt-6 pl-11">
          {sectionBody.List.map((listItem, idx) => (
            <li key={idx} className="list-disc">
              {listItem}
            </li>
          ))}
        </ul>
        <div className="mt-10 hidden lg:block">
          <Button text="Get Started" width="w-[255px]" onPress={() => {}} />
        </div>
      </div>
    </div>
  );
}
