import { m } from "framer-motion";
import { useContext } from "react";
import { SignUpContext } from "./NavBar";

interface IModal {
  children: React.ReactNode;
}

export default function Modal({ children }: IModal) {
  const setIsModalOpen = useContext(SignUpContext);

  const handleCloseModal = () => {
    if (setIsModalOpen) {
      setIsModalOpen(false);
      document.body.style.overflow = "auto";
    }
  };

  return (
    <>
      <m.div
        className="fixed inset-0 z-[1000] flex items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* Backdrop */}
        <m.div
          className="backdrop"
          onClick={handleCloseModal}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        />

        {/* Modal container */}
        <m.div
          className="relative z-50 mx-4 flex flex-col items-center rounded-lg bg-white p-8 shadow-xl sm:w-full sm:max-w-2xl"
          initial={{ scale: 0.9, y: 50, opacity: 0 }}
          animate={{ scale: 1, y: 0, opacity: 1 }}
          exit={{ scale: 0.9, y: 50, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {children}
        </m.div>
      </m.div>
    </>
  );
}
